import './Projects.scss';
import {Loader} from 'react-loaders';
import AnimatedLetters from '../AnimatedLetters/AnimatedLetters';
import { useState, useEffect } from 'react';
import WebProject from '../webProject/webProject';
import SWimg from '../../assets/images/SW.jpg'
import siLogo from '../../assets/images/silogiair.png'

const Projects = () => {

    const textTitle = 'Réalisations'.split('');

    const [letterClass, setLetterClass] = useState('text-animate');

    useEffect(() => {
        setTimeout(() => {
            setLetterClass('text-animate-hover')
        }, 3000);
      });

    return (
        <>
            <div className='container projects-page'>

            <a href="#" class="arrow-container">
              <div class="arrow"></div>
              <div class="arrow"></div>
              <div class="arrow"></div>  
            </a>
                
                <div className='text-zone'>
                    <h1>    
                       &nbsp;<AnimatedLetters strArray={textTitle} idx={5}/>&nbsp;
                    </h1>
                    <p>
                        &nbsp; Vous trouverez ici les différents projets que j'ai pu réalisé au cours de ma formation 
                    mais également des projets réalisés pour mon simple plaisir personnel.
                    </p>                    
                </div>
                <div className='projects'>
                    <WebProject 
                    title={'StarWars API'} 
                    link={'https://starwarsapi.sofianeaz.com'}
                    description={'Projet initial sur le JavaScript, portant sur l\'univers StarWars.'} 
                    imgSrc={'https://static.independent.co.uk/s3fs-public/thumbnails/image/2014/11/06/17/star-wars-force-awakens.jpg?quality=75&width=982&height=726&auto=webp'} />

                    <WebProject 
                    title={'SI Consulting'} 
                    link={'https://si-consulting-app.sofianeaz.com'} 
                    description={'Projet pour l\'entreprise SI Consulting, porté beaucoup plus sur le Backend.'} 
                    imgSrc={siLogo} />
                    <WebProject 
                    title={'Shopikami'} 
                    link={'https://shopikami.sofianeaz.com'} 
                    description={'Projet personnel, prototype de site eCommerce avec React.'} 
                    imgSrc={'https://i.ibb.co/7vfddC7/japan-women-japanese-clothes-kimono-wallpaper-preview.jpg'} />
                    <WebProject 
                    description={'Projet à venir prochainement.'} 
                    />

           
                
                </div>
                
            </div>

            <Loader type="cube-transition" />
        </>
    )
}

export default Projects;