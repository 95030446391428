import './App.scss';
import {Routes, Route} from 'react-router-dom';
import Layout from './components/Layout/Layout';
import Home from './components/Home/Home';
import About from './components/About/About';
import Projects from './components/Projects/Projects';
import Contact from './components/Contact/Contact';


function App() {
  return (
    <>
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="/About" element ={<About />} />
        <Route path="/Projects" element ={<Projects />} />
        <Route path="/Contact" element ={<Contact />} />
      </Route>
    </Routes>
    </>
  );
}

export default App;
