import './webProject.scss';
import altImg from '../../assets/images/ComingSoon.jpg'



const WebProject = ({title, link, description, imgSrc}) => {
    let LinkA =  "javascript:void(0)";
    let text = "Aperçu"

    // `>Bientôt disponible</a>`
    // `<a target='_blank' href="${link}">Visiter</a>`

    return (
        <>
        <div className='card'>
            <img src={imgSrc == null ? altImg : imgSrc} alt={title+' '+1} />
            <h1>{link == null ? 'En travaux' :title}</h1>

            <a target={link == null ? '': '_blank'} href={link == null ? LinkA : link}>{link == null ? 'Bientôt' : text}</a>
            <p> {description}</p>
        </div>
        </>
    )
}

export default WebProject;