import './sidebar.scss';
import './sidebarMobile.scss';
import { useEffect, useState } from 'react';
import {Link, NavLink} from 'react-router-dom';
import LogoS from '../../assets/images/Letter-S-PNG.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faHome, faUser,  faLaptop} from '@fortawesome/free-solid-svg-icons';

const Sidebar = () => {
    // const [mobileClass, setMobileClass] = useState('active');
    const [isOpen, setOpen] = useState(false);
    const toggleClass = () => {
        // e.preventDefault();
        if(window.innerWidth < 800){
            setOpen(!isOpen);
            document.querySelector('#nav').classList.toggle('hide');
        }
        
      };
      
      const hideNav = () => {
        setTimeout(doIt, 300);
     
      }
      function doIt(){
        let myClass;
        document.querySelector('#nav').classList.toggle('hide');
        let classified = document.querySelector('#mobile-button').getAttribute('class');
        classified = 'mobile-button open' ?  myClass = 'mobile-button notopen' : myClass = 'mobile-button open';
        document.querySelector('#mobile-button').setAttribute('class', myClass);
    }

    return (
        <>
    <div className='nav-bar'>
        <Link className='logo' to='/'>
            <img src={LogoS} alt='logo' />
        </Link>
        <button id="mobile-button" className={isOpen ? 'mobile-button open': 'mobile-button notopen'} onClick={toggleClass}>
                <span  className='mobile-burger'></span>
                <span  className='mobile-burger'></span>
                <span  className='mobile-burger'></span>
        </button>
        <nav id="nav" className={ window.innerWidth < 800 ? isOpen ? '' : 'hide' : ''}>
           
            <NavLink 
            exact="true" 
            activeclassname="active" 
            to="/"
            onClick={toggleClass}
            >
                <FontAwesomeIcon icon={faHome} color="rgb(208, 238, 218);" />
            </NavLink>
            <NavLink 
                exact="true" 
                activeclassname="active" 
                className="about-link" 
                to="/about"
                onClick={toggleClass}
            >
                <FontAwesomeIcon icon={faUser} color="rgb(208, 238, 218);" />
            </NavLink>
            <NavLink  
                exact="true" 
                activeclassname="active" 
                className="project-link" 
                to="/Projects"
                onClick={toggleClass}
            >
               <FontAwesomeIcon icon={faLaptop} />
            </NavLink>
            <NavLink 
                exact="true" 
                activeclassname="active" 
                className="contact-link" 
                to="/contact"
                onClick={toggleClass}
            >
                <FontAwesomeIcon icon={faEnvelope} color="rgb(208, 238, 218);" />
               
            </NavLink>
        </nav>
        <ul>
            <li>
                <a target="_blank" rel="noreferrer" href='https://www.youtube.com/channel/UCT4HfP5hXNHDBuHYsAD9Nug'>
                    <i className="fa-brands fa-youtube" > </i>
                </a>
            </li>
            <li>
                <a target="_blank" rel="noreferrer" href='https://www.facebook.com/sofiane.azougar'>
                    <i className="fa-brands fa-facebook" > </i>
                </a>
            </li>
            <li>
                <a target="_blank" href='https://www.linkedin.com/in/sofiane-azougar-dev-front-back'>
                    <i className="fa-brands fa-linkedin-in" ></i>
                </a>
            </li>
            
        </ul>
    </div>
    </>
    )
}

export default Sidebar;