import './home.scss';
import { useState, useEffect } from 'react';
import LogoTitle from '../../assets/images/avatar-50.png';
import { Link } from 'react-router-dom';
import AnimatedLetters from '../AnimatedLetters/AnimatedLetters';
import Logo from './Logo/Logo';
import {Loader} from 'react-loaders';

const Home = () => {
    const myName = "ofiane";
    const text = "développeur web.";
    const [letterClass, setLetterClass] = useState('text-animate');
    const nameArray = myName.split('');
    const jobArray = text.split('');


      useEffect(() => {
        setTimeout(() => {
            setLetterClass('text-animate-hover')
        }, 4000);
      });

    return (
        <>
        <div className='container home-page'>
            <div className='text-zone'>
                <h1>
                <span className={`${letterClass}`}>B</span> 
                <span className={`${letterClass} _6`}>O</span>    
                <span className={`${letterClass} _7`}>N</span>    
                <span className={`${letterClass} _8`}>J</span>    
                <span className={`${letterClass} _9`}>O</span>       
                <span className={`${letterClass} _10`}>U</span>    
                <span className={`${letterClass} _11`}>R,</span>  
                <br />  
                <span className={`${letterClass} _12`}>J</span>   
                <span className={`${letterClass} _13`}>E</span> 
                    &nbsp;   
                <span className={`${letterClass} _14`}>S</span>     
                <span className={`${letterClass} _15`}>U</span>    
                <span className={`${letterClass} _16`}>I</span>    
                <span className={`${letterClass} _17`}>S</span>    
                <br/>
                <img src={LogoTitle} alt="developpeur" />
                <AnimatedLetters letterClass={letterClass} strArray={nameArray} idx={18}/>
                <br />
                <AnimatedLetters letterClass={letterClass} strArray={jobArray} idx={25}/>
                </h1>
                <h4> Développeur Front-end / Back-end </h4>
                <Link to="/contact" className='flat-button'>CONTACTEZ-MOI</Link>
            </div>
            <Logo />
        </div>
        <Loader type="cube-transition" />
        </>
    );
}

export default Home;