import { useEffect, useRef } from 'react'
import myPic from '../../../assets/images/Letter-S-PNG.png';
import './Logo.scss';



const Logo = () =>{

    return (

    <div className="logo-container" >
        <img
        className="solid-logo"
        src={myPic}
        alt="JavaScript,  Developer"
        />
       
    </div>
            );
    }


export default Logo;