import './About.scss';
import AnimatedLetters from '../AnimatedLetters/AnimatedLetters';
import { useEffect, useState } from 'react';
import {Loader} from 'react-loaders';

import reactIcon from '../../assets/images/react-icon.png';
import html5Icon from '../../assets/images/html5-icon.png';
import mysqlIcon from '../../assets/images/mysql-Icon.png';
import laravelIcon from '../../assets/images/Laravel-icon.png';
import jsIcon from '../../assets/images/js-icon.png';
import scssIcon from '../../assets/images/scss-icon.png';






const About = () => {
    const [letterClass, setLetterClass] = useState('text-animate');
    const text = 'À propos de moi';
    const textArr = text.split('');
    const compText = 'Compétences'.split('')
    

    useEffect(() => {
        setTimeout(() => {
            setLetterClass('text-animate-hover')
        }, 2500)        
    }, []);
    return (
        <>
        <div className='container about-page'>
            <div className='text-zone'>
                <h1>
                    <AnimatedLetters 
                    letterClass={letterClass}
                    strArray={textArr}
                    idx={5}
                    />
                </h1>
                <br/>
                <p>
                    &nbsp; Je suis un ambitieux développeur qui vient tout juste de commencer sa carrière
                    suite à une reconversion. En l'espace de 8 mois, j'ai su acquérir de nombreuses compétences
                    comme le peuvent en témoigner mes différents projets.
                </p>
                <br/>
                <p align="LEFT">
                    
                    &nbsp; Je suis maintenant à la recherche d'une entreprise qui me permettra d'utiliser
                    tout mon potentiel et qui me donnera l'opportunité de travailler avec les dernières technologies.

                </p>
                <br/>
               
                <h1>
                <AnimatedLetters 
                    letterClass={letterClass}
                    strArray={compText}
                    idx={5}
                    />
                   
                </h1>
                <div className='flex space-even'>
                    <div className='flex col align padding-1'>
                        <h2>Front-End</h2><br/>
                        
                        <h3>Langages :</h3>
                        <p>
                                 H T M L 5<br/> 
                                 C S S 3<br/> 
                                 JavaScript <br/>
                                 S C S S<br/>
                        </p>
                        <h3>Librairies :</h3>
                        <p>
                            React.JS<br/>
                            Vue.JS <br/>
                            BootStrap<br/>                 
                        </p>
                    </div>
                    <div className='flex col align padding-1'>
                        <h2>Back-end</h2><br/>
                        <h3>Langages :</h3>
                        <p>
                        
                            PHP8,<br/> 
                            MySQL.<br/>
                        </p>
                            <h3>Librairies :</h3>

                        <p>
                            Laravel.<br/>
                        </p>
                    </div>
                 
                </div>
            </div>

            <div className='stage-cube-cont'>
                <div className='cubespinner'>
                        <div className="face1">
                            <img src={reactIcon} className="" alt='react icon' />
                        </div>
                        <div className="face2">
                            <img src={html5Icon}  className="" alt='html5 icon' />
                        </div>
                        <div className="face3">
                            <img src={mysqlIcon}  className="" alt='mysql icon' />
                        </div>
                        <div className="face4">
                            <img src={laravelIcon}  className="" alt='laravel icon' />
                        </div>
                        <div className="face5">
                            <img src={jsIcon}  className="" alt='js icon' />
                        </div>
                        <div className="face6">
                            <img src={scssIcon}  className="" alt='scss icon' />
                        </div>
                </div>
            </div>
        </div>
        <Loader type="cube-transition" />
        </>
    )
}

export default About;