import './Contact.scss';
import { useState, useEffect, useRef } from 'react';
import {Loader} from 'react-loaders';
import AnimatedLetters from '../AnimatedLetters/AnimatedLetters';
import emailjs from '@emailjs/browser';
import { MapContainer, Marker, TileLayer, Popup } from 'react-leaflet';

const Contact = () => {

    const form = useRef();
    const contactText = 'Contactez-moi'.split('');

    const [letterClass, setLetterClass] = useState('text-animate');

    useEffect(() => {
        setTimeout(() => {
            setLetterClass('text-animate-hover')
        }, 3000);
      });

      const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm( 'service_kcz2dge',
                            'template_wktb1fg',
                             form.current,
                            '0zFiSDOZKrkvApS8o')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
      };

    return (
        <>
        <div className='container contact-page'>
            <div className='text-zone'>
                <h1>
                    <AnimatedLetters
                    letterClass={letterClass}
                     strArray={contactText}
                     idx={5}
                      />
                </h1>
                <p>
                    Je suis intéressé par les opportunités de FreeLance - surtout par les projets larges et ambitieux. 
                </p>
                <div className='contact-form'>
                    <form ref={form} onSubmit={sendEmail}>
                        <ul>
                            <li className='half'>
                                <input type="text" name="user_name" placeholder='Votre nom' required />
                                <input type="text" name="subject" placeholder="Votre sujet"  required />
                            </li>
                            <li>
                                <input type="email" name="user_email" placeholder="Votre adresse mail" required /> 
                            </li>
                            <li>
                                <textarea  name='message' placeholder="Votre message" >

                                </textarea>
                            </li>
                            <li>
                                <input type="submit" className='flat-button' value="Envoyer" />
                            </li>
                        </ul>
                    </form>
                </div>
            </div>
            <div className='info-map'>
                Azougar Sofiane,
                <br/>
                France, 
                <br/>
                54000 Nancy
                <span>sofiane.azougar@gmail.com</span>
            </div>
            <div className='map-wrap'>
            <MapContainer 
                center={[48.692054, 6.184417]}
                zoom={13}
            >
                <TileLayer
                    attribution='donn&eacute;es &copy; <a href="//osm.org/copyright">OpenStreetMap</a>/ODbL - rendu <a href="//openstreetmap.fr">OSM France</a>'
                   url="http://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png"
                 />
   
                <Marker position={[48.693054, 6.189500]}>
                    <Popup>C'est ici que je vis ! Venez donc boire un café avec moi</Popup>
                </Marker>
            </MapContainer>


                
            </div>
        </div>
        <Loader type="cube-transition" />
        </>
    )
}

export default Contact;